'use strict';
app.factory('checkrollService', function ($rootScope) {

    //Why need this?
    //Before approval, we need to populate the data into grid first
    //This applies to either approve from backend, or from quarto
    //Eg: Worker W1, Block B1 and B2, Rate Type R1
    //There would be 2 records. But MD is 1, as there is only 1 Det
    //Assume ismapa flag already factor in during query
    const formatQCHarvestingDataToQuartoDS = (detailsGroupByDateGang, OUKey) => {
        const _ffbhdet = [];
        const _ffbhblkdet = [];
        detailsGroupByDateGang.forEach((_val, _ind) => {
            const { EmpyKey, EmpyCode, EmpyCodeEmpyDesc, HarvestingIds, Ripe, Others, PayBunches, HarRate,
                BlockKey, BlockCode, EnableConvRate, EnableMacRate, EnableTallPalm, IsPHRD, RateTypeCode, Bunches = 0 } = _val;
            let indexEmpy = _.findIndex(_ffbhdet, (b) => b.EmpyKey == EmpyKey);

            //Handle if QC input different Rate type for a worker and block 
            //Need user himself to fix this issue
            const needNewRow = indexEmpy !== -1 && _ffbhblkdet.some(p => p.EmpyKey === EmpyKey && p.BlockKey === BlockKey && p.RateTypeCode !== RateTypeCode);
            const seqNo = (indexEmpy === -1 || needNewRow) ? _ffbhdet.length + 1 : _ffbhdet[indexEmpy].SeqNo;
            if (indexEmpy === -1 || needNewRow) {
                const empyDetails = {
                    FFBHarDetKey: 0,
                    FFBHarHdrKey: 0,
                    ClientKey: 0,
                    OUKey,
                    EmpyKey: EmpyKey,
                    EmpyID: EmpyCode,
                    EmpyIDEmpyName: EmpyCodeEmpyDesc,
                    MD: 1,
                    SeqNo: seqNo,
                    RowState: $rootScope.RowState.Added,
                    IsSelect: false,
                    IsPHRD
                };
                _ffbhdet.push(empyDetails);
                indexEmpy = _ffbhdet.length - 1;
            }
            const harBlkDet = {
                FFBHarBlkDetKey: 0,
                FFBHarDetKey: 0,
                MblHarvId: HarvestingIds,
                EmpyKey,
                Ripe,
                Others,
                Penalty: 0,
                PayableBch: PayBunches,
                PayableWt: 0,
                Rate: HarRate,
                RateTypeCode,
                RateType: RateTypeCode, //for approval without opening form
                GrossAmt: 0,
                Productivity: 0,
                PenaltyAmt: 0,
                BlockKey,
                SeqNo: seqNo,
                FFBHarHdrKey: 0,
                BlockCode,
                EnableConvRate,
                EnableMacRate,
                EnableTallPalm,
                HarRate,
                Bunches: PayBunches,
                RowState: $rootScope.RowState.Added
            }
            _ffbhblkdet.push(harBlkDet);

            //update employee block's harvesting details
            _ffbhdet[indexEmpy]["MBL" + harBlkDet.BlockKey] = harBlkDet.MblHarvId;
            _ffbhdet[indexEmpy]["R" + harBlkDet.BlockKey] = harBlkDet.Ripe;
            _ffbhdet[indexEmpy]["O" + harBlkDet.BlockKey] = harBlkDet.Others;
            _ffbhdet[indexEmpy]["P" + harBlkDet.BlockKey] = harBlkDet.Penalty;
            _ffbhdet[indexEmpy]["PB" + harBlkDet.BlockKey] = harBlkDet.PayableBch;
            _ffbhdet[indexEmpy]["Rate" + harBlkDet.BlockKey] = harBlkDet.Rate;
            _ffbhdet[indexEmpy]["RateType" + harBlkDet.BlockKey] = harBlkDet.RateTypeCode;
        });

        //Update the MD afterwards (due to unable to populate MD while forming the grid data)
        _ffbhdet.forEach((a, _ind) => {
            const totalThisEmpyCount = _ffbhdet.filter(b => b.EmpyKey === a.EmpyKey).length;
            const mdEach = parseFloat((1 / totalThisEmpyCount).toFixed(1));
            const isLastEmpyInListOfHim = _ind === _ffbhdet.map((b, dInd) => ({ EmpyKey: b.EmpyKey, dInd })).filter(b => b.EmpyKey === a.EmpyKey).at(-1).dInd;
            a.MD = isLastEmpyInListOfHim ? (1 - (totalThisEmpyCount - 1) * mdEach) : mdEach;
        });

        return [ _ffbhdet, _ffbhblkdet ];
    }

    const formatQCHarvestingInterOUDataToQuartoDS = (detailsGroupByDateGang, OUKey) => {
        const _ffbhdet = [];
        const _ffbhblkdet = [];

        detailsGroupByDateGang.forEach((_val, _ind) => {
            const { EmpyKey, EmpyCode, EmpyCodeEmpyDesc, HarvestingIds, Ripe, Others, PayBunches, HarRate,
                BlockKey, BlockCode, EnableConvRate, EnableMacRate, EnableTallPalm, IsPHRD, RateTypeCode, EnableHRPro } = _val;
            let indexEmpy = _.findIndex(_ffbhdet, (b) => b.EmpyKey == EmpyKey);

            //Handle if QC input different Rate type for a worker and block 
            //Need user himself to fix this issue
            const needNewRow = indexEmpy !== -1 && _ffbhblkdet.some(p => p.EmpyKey === EmpyKey && p.BlockKey === BlockKey && p.RateTypeCode !== RateTypeCode);
            const seqNo = (indexEmpy === -1 || needNewRow) ? _ffbhdet.length + 1 : _ffbhdet[indexEmpy].SeqNo;

            if (indexEmpy === -1 || needNewRow) {
                const empyDetails = {
                    FFBHarDetKey: 0,
                    FFBHarHdrKey: 0,
                    ClientKey: 0,
                    OUKey,
                    EmpyKey: EmpyKey,
                    EmpyID: EmpyCode,
                    EmpyIDEmpyName: EmpyCodeEmpyDesc,
                    MD: 1,
                    SeqNo: seqNo,
                    RowState: $rootScope.RowState.Added,
                    IsSelect: false,
                    IsPHRD
                };
                _ffbhdet.push(empyDetails);
                indexEmpy = _ffbhdet.length - 1;
            }
            const harBlkDet = {
                FFBHarBlkDetKey: 0,
                FFBHarDetKey: 0,
                MblHarvId: HarvestingIds,
                EmpyKey,
                Ripe,
                Others,
                Penalty: 0,
                PayableBch: PayBunches,
                PayableWt: 0,
                Rate: HarRate,
                RateTypeCode,
                RateType: RateTypeCode, //for approval without opening form
                GrossAmt: 0,
                Productivity: 0,
                PenaltyAmt: 0,
                BlockKey,
                SeqNo: seqNo,
                FFBHarHdrKey: 0,
                BlockCode,
                EnableConvRate,
                EnableMacRate,
                EnableTallPalm,
                EnableHRPro,
                HarRate,
                RowState: $rootScope.RowState.Added
            }
            _ffbhblkdet.push(harBlkDet);

            //update employee block's harvesting details
            _ffbhdet[indexEmpy]["MBL" + harBlkDet.BlockKey] = harBlkDet.MblHarvId;
            _ffbhdet[indexEmpy]["R" + harBlkDet.BlockKey] = harBlkDet.Ripe;
            _ffbhdet[indexEmpy]["O" + harBlkDet.BlockKey] = harBlkDet.Others;
            _ffbhdet[indexEmpy]["P" + harBlkDet.BlockKey] = harBlkDet.Penalty;
            _ffbhdet[indexEmpy]["PB" + harBlkDet.BlockKey] = harBlkDet.PayableBch;
            _ffbhdet[indexEmpy]["Rate" + harBlkDet.BlockKey] = harBlkDet.Rate;
            _ffbhdet[indexEmpy]["RateType" + harBlkDet.BlockKey] = harBlkDet.RateTypeCode;
        });

        //Update the MD afterwards (due to unable to populate MD while forming the grid data)
        _ffbhdet.forEach((a, _ind) => {
            const totalThisEmpyCount = _ffbhdet.filter(b => b.EmpyKey === a.EmpyKey).length;
            const mdEach = parseFloat((1 / totalThisEmpyCount).toFixed(1));
            const isLastEmpyInListOfHim = _ind === _ffbhdet.map((b, dInd) => ({ EmpyKey: b.EmpyKey, dInd })).filter(b => b.EmpyKey === a.EmpyKey).at(-1).dInd;
            a.MD = isLastEmpyInListOfHim ? (1 - (totalThisEmpyCount - 1) * mdEach) : mdEach;
        });

        return [_ffbhdet, _ffbhblkdet];
    };

    const formatQCHarvestingDataToQuartoDS_IND = (detailsGroupByDateGang, cropPenaltyByDateGang, OUKey) => {
        try {
            const _ffbhdet = [];
            const _ffbhblkdet = [];
            detailsGroupByDateGang.forEach((_val, _ind) => {
                const { EmpyKey, EmpyCode, EmpyCodeEmpyDesc, HarvestingIds, Ripe, Others, PayBunches, HarRate,
                    BlockKey, BlockCode, EnableConvRate, EnableMacRate, EnableTallPalm, LF, PenaltyAmount } = _val;
                let indexEmpy = _.findIndex(_ffbhdet, (b) => b.EmpyKey == EmpyKey);

                if (indexEmpy === -1) {
                    const empyDetails = {
                        FFBHarDetKey: 0,
                        FFBHarHdrKey: 0,
                        ClientKey: 0,
                        OUKey,
                        EmpyKey: EmpyKey,
                        EmpyID: EmpyCode,
                        EmpyIDEmpyName: EmpyCodeEmpyDesc,
                        MD: 1,
                        SeqNo: 0,
                        RowState: $rootScope.RowState.Added,
                        IsSelect: false,
                        EmpyKey,
                        EmpyID: EmpyCode,
                        EmpyIDEmpyName: EmpyCodeEmpyDesc,
                        MD: 1,
                        SeqNo: 0,
                        RowState: $rootScope.RowState.Added,
                        IsSelect: false
                    };
                    _ffbhdet.push(empyDetails);
                    indexEmpy = _ffbhdet.length - 1;
                }
                const harBlkDet = {
                    FFBHarBlkDetKey: 0,
                    FFBHarDetKey: 0,
                    MblHarvId: HarvestingIds,
                    EmpyKey,
                    Ripe,
                    Others,
                    Penalty: 0,
                    PayableBch: PayBunches,
                    PayableWt: 0,
                    Rate: HarRate,
                    GrossAmt: 0,
                    Productivity: 0,
                    Penalty: PenaltyAmount, //at blkdet level, column name is Penalty
                    BlockKey,
                    FFBHarHdrKey: 0,
                    BlockCode,
                    EnableConvRate,
                    EnableMacRate,
                    EnableTallPalm,
                    HarRate,
                    Bunches: PayBunches,
                    LF,
                    RowState: $rootScope.RowState.Added
                }
                _ffbhblkdet.push(harBlkDet);

                //update employee block's harvesting details
                _ffbhdet[indexEmpy]["MBL" + harBlkDet.BlockKey] = harBlkDet.MblHarvId;
                _ffbhdet[indexEmpy]["R" + harBlkDet.BlockKey] = harBlkDet.Ripe;
                _ffbhdet[indexEmpy]["O" + harBlkDet.BlockKey] = harBlkDet.Others;
                _ffbhdet[indexEmpy]["P" + harBlkDet.BlockKey] = harBlkDet.Penalty;
                _ffbhdet[indexEmpy]["PB" + harBlkDet.BlockKey] = harBlkDet.PayableBch;
                _ffbhdet[indexEmpy]["Rate" + harBlkDet.BlockKey] = harBlkDet.Rate;
                _ffbhdet[indexEmpy]["A" + harBlkDet.BlockKey] = harBlkDet.PenaltyAmt;
                _ffbhdet[indexEmpy]["L" + harBlkDet.BlockKey] = harBlkDet.LF;
            });

            //Update the MD afterwards (due to unable to populate MD while forming the grid data)
            _ffbhdet.forEach((a, _ind) => {
                const totalThisEmpyCount = _ffbhdet.filter(b => b.EmpyKey === a.EmpyKey).length;
                const mdEach = parseFloat((1 / totalThisEmpyCount).toFixed(1));
                const isLastEmpyInListOfHim = _ind === _ffbhdet.map((b, dInd) => ({ EmpyKey: b.EmpyKey, dInd })).filter(b => b.EmpyKey === a.EmpyKey).at(-1).dInd;
                a.MD = isLastEmpyInListOfHim ? (1 - (totalThisEmpyCount - 1) * mdEach) : mdEach;
            });

            const _ffbhpenaltydet = cropPenaltyByDateGang.map(a => {
                a.RowState = $rootScope.RowState.Added;
                return a;
            });;

            return [_ffbhdet, _ffbhblkdet, _ffbhpenaltydet];
        }
        catch (ex) {
            console.error(ex);
        }
    }

    const formatQCHarvestingInterOUDataToQuartoDS_IND = (detailsGroupByDateGang, cropPenaltyByDateGang, OUKey) => {
        try {
            const _ffbhdet = [];
            const _ffbhblkdet = [];
            detailsGroupByDateGang.forEach((_val, _ind) => {
                const { EmpyKey, EmpyCode, EmpyCodeEmpyDesc, HarvestingIds, Ripe, Others, PayBunches, HarRate,
                    BlockKey, BlockCode, EnableConvRate, EnableMacRate, EnableTallPalm, LF, PenaltyAmount } = _val;
                let indexEmpy = _.findIndex(_ffbhdet, (b) => b.EmpyKey == EmpyKey);

                if (indexEmpy === -1) {
                    const empyDetails = {
                        FFBHarDetKey: 0,
                        FFBHarHdrKey: 0,
                        ClientKey: 0,
                        OUKey,
                        EmpyKey: EmpyKey,
                        EmpyID: EmpyCode,
                        EmpyIDEmpyName: EmpyCodeEmpyDesc,
                        MD: 1,
                        SeqNo: 0,
                        RowState: $rootScope.RowState.Added,
                        IsSelect: false,
                        EmpyKey,
                        EmpyID: EmpyCode,
                        EmpyIDEmpyName: EmpyCodeEmpyDesc,
                        MD: 1,
                        SeqNo: 0,
                        RowState: $rootScope.RowState.Added,
                        IsSelect: false
                    };
                    _ffbhdet.push(empyDetails);
                    indexEmpy = _ffbhdet.length - 1;
                }
                const harBlkDet = {
                    FFBHarBlkDetKey: 0,
                    FFBHarDetKey: 0,
                    MblHarvId: HarvestingIds,
                    EmpyKey,
                    Ripe,
                    Others,
                    Penalty: 0,
                    PayableBch: PayBunches,
                    PayableWt: 0,
                    Rate: HarRate,
                    GrossAmt: 0,
                    Productivity: 0,
                    Penalty: PenaltyAmount, //at blkdet level, column name is Penalty
                    BlockKey,
                    FFBHarHdrKey: 0,
                    BlockCode,
                    EnableConvRate,
                    EnableMacRate,
                    EnableTallPalm,
                    HarRate,
                    Bunches: PayBunches,
                    LF,
                    RowState: $rootScope.RowState.Added
                }
                _ffbhblkdet.push(harBlkDet);

                //update employee block's harvesting details
                _ffbhdet[indexEmpy]["MBL" + harBlkDet.BlockKey] = harBlkDet.MblHarvId;
                _ffbhdet[indexEmpy]["R" + harBlkDet.BlockKey] = harBlkDet.Ripe;
                _ffbhdet[indexEmpy]["O" + harBlkDet.BlockKey] = harBlkDet.Others;
                _ffbhdet[indexEmpy]["P" + harBlkDet.BlockKey] = harBlkDet.Penalty;
                _ffbhdet[indexEmpy]["PB" + harBlkDet.BlockKey] = harBlkDet.PayableBch;
                _ffbhdet[indexEmpy]["Rate" + harBlkDet.BlockKey] = harBlkDet.Rate;
                _ffbhdet[indexEmpy]["A" + harBlkDet.BlockKey] = harBlkDet.PenaltyAmt;
                _ffbhdet[indexEmpy]["L" + harBlkDet.BlockKey] = harBlkDet.LF;
            });

            //Update the MD afterwards (due to unable to populate MD while forming the grid data)
            _ffbhdet.forEach((a, _ind) => {
                const totalThisEmpyCount = _ffbhdet.filter(b => b.EmpyKey === a.EmpyKey).length;
                const mdEach = parseFloat((1 / totalThisEmpyCount).toFixed(1));
                const isLastEmpyInListOfHim = _ind === _ffbhdet.map((b, dInd) => ({ EmpyKey: b.EmpyKey, dInd })).filter(b => b.EmpyKey === a.EmpyKey).at(-1).dInd;
                a.MD = isLastEmpyInListOfHim ? (1 - (totalThisEmpyCount - 1) * mdEach) : mdEach;
            });

            const _ffbhpenaltydet = cropPenaltyByDateGang.map(a => {
                a.RowState = $rootScope.RowState.Added;
                return a;
            });;

            return [_ffbhdet, _ffbhblkdet, _ffbhpenaltydet];
        }
        catch (ex) {
            console.error(ex);
        }
    }


    return {
        formatQCHarvestingDataToQuartoDS,
        formatQCHarvestingInterOUDataToQuartoDS,
        formatQCHarvestingDataToQuartoDS_IND,
        formatQCHarvestingInterOUDataToQuartoDS_IND
    }
});